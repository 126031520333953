<template>

  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card justify-center>
          <v-card-title>
            <v-spacer />
            <v-progress-circular
              :size="200"
              color="primary"
              indeterminate
              class="mt-8"
            >
              <v-img src="witness_eye.png" max-width="150" />
            </v-progress-circular>
            <v-spacer />
          </v-card-title>
          <v-card-text justify="center" class="mt-6 text-center" style="line-height: normal;">
            <h1>
              <strong class="mb-8">{{userMessage}}</strong>
            </h1>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
      <!-- <h1>
        <strong class="ml-10">{{userMessage}}</strong>
      </h1> -->
</template>

<script>

import loginViewModel from '@/view-models/login-view-model';

export default loginViewModel;

</script>