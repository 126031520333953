import dayjs from 'dayjs';
import jwt from 'jsonwebtoken';
import { mapGetters } from 'vuex';
import userAuthentication from '@/models/common/user-authentication';

var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

export default {
  name: 'Login',
  
  data() {
    return {
      userMessage: 'Contacting authorization service...',
      debounceTimer: undefined
    };
  },
  methods: {
    loginChecks: function () {
      let urlParams = new URLSearchParams(window.location.hash);
      
      const errorDescriptionCode = urlParams?.get('error_description')?.substring(0, 11);
      if (errorDescriptionCode != null) {
        this.checkForForgottenPasswordError(errorDescriptionCode);
        urlParams = new URLSearchParams(window.location.hash);
      }
      
      const idToken = urlParams?.get('id_token');
      if (idToken === null) {
        return;
      }
      if (window.parent != window)
      {
        let event = new CustomEvent('RefreshIdToken', { detail: idToken });
        window.parent.document.dispatchEvent(event);
      }

      let decodedToken = jwt.decode(idToken);

      if (dayjs.utc().unix() > decodedToken?.exp) {
        userAuthentication.redirectUserToLogIn();
      }

      this.continueWithValidIDToken(idToken);
    },
    checkForForgottenPasswordError: function (errorDescriptionCode) {
      if (errorDescriptionCode == 'AADB2C90118') {
        userAuthentication.redirectUserToChangePassword();
      }
    },
    continueWithValidIDToken: async function (idToken) {
      this.$store.commit('updateIdToken', idToken);

      this.userMessage = 'Retrieving user information...';
      this.$store.dispatch('fetchAndStoreClinicAndDeviceInfo');

      this.debounceTimer = setTimeout(() => {
        this.userMessage = 'Oops, this is taking longer than expected. Please refresh the page and try again.';
      }, 5000);
    }
  },
  computed: {
    ...mapGetters({
      currentClinic: 'currentClinic',
      allClinics: 'allClinics',
      emailAddress: 'emailAddress',
      isSysAdmin: 'isSysAdmin',
      isGlobalAdmin: 'isGlobalAdmin',
      idToken: 'idToken'
    })
  },
  mounted() {
    this.loginChecks();
  },
  watch: {
    currentClinic: function (newVal, oldVal) {
      if (this.allClinics && this.currentClinic) {
        this.$router.push('witnessStatistics');
      }
    }
  },
  beforeDestroy () {
    if (this.debounceTimer){
      clearTimeout(this.debounceTimer);
    }
  }
};